import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
import { clientApi } from "../../api/clientApi";

export const getBalance = createAsyncThunk('wallet/getBalance', async (params) => {
  const walletBalance = await client.get('/wallet/balance',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      
    }
  });

  return walletBalance.data;
});



export const getTopUp = createAsyncThunk('wallet/TopUp', async (obj) => {
  const walletBalance = await client.get(`/wallet/payment/manual/${obj.amount}`,
  {
    "headers":
    {
      "Authorization": "Bearer " + obj.accessToken,
      'Accept': 'text/plain'
    }
  });
  return walletBalance.data;
});
 



export const getDebits = createAsyncThunk('wallet/getDebits', async (params) => {
  const debits = await client.get('/wallet/debit',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return debits.data;
});

export const getCredits = createAsyncThunk('wallet/getCredits', async (params) => {
  const credits = await client.get('/wallet/credit',
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken
    }
  });

  return credits.data;
});

export const getPaymentGateways = createAsyncThunk('wallet/getpaymentgateways', async (accessToken) => {
  const providers = await client.get('/wallet/getpaymentgateways',
  {
    "headers":
    {
      "Authorization": "Bearer " + accessToken
    }
  });

  return providers.data;
})

export const initiatePayment = createAsyncThunk('wallet/initiatePayment', async (params) => {
  let data = {
    userKey: params.userKey,
    email: params.email,
    amount: params.amount,
    paymentGatewayKey: params.paymentGatewayKey
  };

  data = JSON.stringify(data)

  const payment = await client.post('/wallet/payment/initiate',data,
  {
    "headers":
    {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  });

  return payment;
})

export const updateWalletFunds = createAsyncThunk('updateFunds', async (params) => {
   const wallet = await client.get(`/wallet/payment/process/${params.reference.reference}/${params.paymentKey}`,
   {
     "headers":
     {
       "Authorization": "Bearer " + params.accessToken
     }
   });

   return wallet;
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    currentBalance: null,
    balanceStatus: FetchDataStatus.INITIAL,
    debits: [],
    debitsStatus: FetchDataStatus.INITIAL,
    credits: [],
    creditsStatus: FetchDataStatus.INITIAL,
    providers: [],
    providersStatus: FetchDataStatus.INITIAL,
    initiatePayment: {},
    initiatePaymentStatus: PostDataStatus.INITIAL,
    updateWalletFundsStatus: FetchDataStatus.INITIAL,
    isError: false,
    errorMessage: null,

    topupStatus: FetchDataStatus.INITIAL,
    topupIsError: false,
    topupLoad:{},
    topupErrorMessage: null
  },
  reducers: {
    resetWallet: state => {
      state.isError = false;
      state.errorMessage = null;
      state.currentBalance = null;
      state.balanceStatus = FetchDataStatus.INITIAL;
      state.debits = [];
      state.debitsStatus = FetchDataStatus.INITIAL;
      state.credits = [];
      state.creditsStatus = FetchDataStatus.INITIAL;
      state.providers = [];
      state.providersStatus = FetchDataStatus.INITIAL;
      state.initiatePayment = {};
      state.initiatePaymentStatus = PostDataStatus.INITIAL;
      state.updateWalletFundsStatus = FetchDataStatus.INITIAL;
    },
    resetWalletExceptBalance: state => {
      state.isError = false;
      state.errorMessage = null;
      state.debits = [];
      state.debitsStatus = FetchDataStatus.INITIAL;
      state.credits = [];
      state.creditsStatus = FetchDataStatus.INITIAL;
      state.providers = [];
      state.providersStatus = FetchDataStatus.INITIAL;
      state.initiatePayment = {};
      state.initiatePaymentStatus = PostDataStatus.INITIAL;
      state.updateWalletFundsStatus = FetchDataStatus.INITIAL;
    },
    resetInitializePayment: state => {
      state.isError = false;
      state.errorMessage = null;
      state.initiatePayment = {};
      state.initiatePaymentStatus = PostDataStatus.INITIAL;
    },

    resetTopUp: state => {
      state.topupIsError = false;
      state.topupErrorMessage = null;
      state.topupLoad = {};
      state.topupStatus = FetchDataStatus.INITIAL;
    }
  },
  extraReducers(builder) {
    builder.addCase(getBalance.pending, (state) => {
      state.balanceStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getBalance.fulfilled, (state, action) => {
      state.balanceStatus = FetchDataStatus.SUCCESS;
      state.currentBalance = action.payload;
    })
    .addCase(getBalance.rejected, (state, action) => {
      state.balanceStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })


    builder.addCase(getTopUp.pending, (state) => {
      state.topupStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getTopUp.fulfilled, (state, action) => {
      state.topupStatus = FetchDataStatus.SUCCESS;
      state.topupLoad = action.payload;
    })
    .addCase(getTopUp.rejected, (state, action) => {
      state.topupStatus = FetchDataStatus.FAILURE;
      state.topupIsError = true;
      state.topupErrorMessage = action.error.message;
    })


    .addCase(getDebits.pending, (state) => {
      state.debitsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getDebits.fulfilled, (state, action) => {      
      state.debitsStatus = FetchDataStatus.SUCCESS;
      state.debits = action.payload;
    })
    .addCase(getDebits.rejected, (state, action) => {
      state.debitsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getCredits.pending, (state) => {
      state.creditsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getCredits.fulfilled, (state, action) => {      
      state.creditsStatus = FetchDataStatus.SUCCESS;
      state.credits = action.payload;
    })
    .addCase(getCredits.rejected, (state, action) => {
      state.creditsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(getPaymentGateways.pending, (state) => {
      state.providersStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getPaymentGateways.fulfilled, (state, action) => {
      state.providers = action.payload;
      state.providersStatus = FetchDataStatus.SUCCESS;
    })
    .addCase(getPaymentGateways.rejected, (state, action) => {
      state.providersStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(initiatePayment.pending, (state) => {
      state.initiatePaymentStatus = PostDataStatus.FETCHING;
    })
    .addCase(initiatePayment.fulfilled, (state, action) => { 
      state.initiatePayment = action.payload;     
      state.initiatePaymentStatus = PostDataStatus.SUCCESS;
    })
    .addCase(initiatePayment.rejected, (state, action) => {
      state.initiatePaymentStatus = PostDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(updateWalletFunds.pending, (state) => {
      state.updateWalletFundsStatus = FetchDataStatus.FETCHING;
    })
    .addCase(updateWalletFunds.fulfilled, (state) => {      
      state.updateWalletFundsStatus = FetchDataStatus.SUCCESS;
    })
    .addCase(updateWalletFunds.rejected, (state, action) => {
      state.updateWalletFundsStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
  }
});

export const { loading, wallet, resetWallet, resetInitializePayment, resetWalletExceptBalance,resetTopUp } = walletSlice.actions;

export const selectWalletState = state => state.wallet;

export default walletSlice.reducer;