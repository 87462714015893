import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import errorImg from '../../../assests/icons/errorImage.png';
import Utils from "../../../library/Utils";
import { FetchDataStatus, PostDataStatus } from "../../../library/Variables";
import { getApplicationAsync, selectApplicationState } from "../../../redux/Slices/Application";
import { getHookDetails, resetUpdateHook, selectHookSlice, updateHookDetails } from "../../../redux/Slices/webHook/WebHookClient";
import Spinner from "../../../svg/Spinner/Spinner";

function Api(props) {
  let content = <Spinner />
  let contentTwo = <Spinner/>
  let contentThree = <Spinner/>
  const hookSelector = useSelector(selectHookSlice)
  const accessToken = props.accessToken;
  const configuration = props.configuration
  const user = props.user
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [HookUrl,setHookUrl] = useState('')
  const application = useSelector(selectApplicationState);
  const monitoringBaseUrl = process.env.REACT_APP_WEBHOOK_MONITORING_URL


  const navigateToSelfService = ()=>{
    navigate('/DecisionEngineSelfService')
  }

  const updateHook = (value,item)=>{
    let hookObj
    if(item==='hookset'){
       hookObj = {
        "identifier":configuration.identifier,
        "publicKey": configuration.publicKey,
        "webhookUrl": value,
        "rotateEncryptionKey": false,
        "enableInsightsWebhook": hookSelector.fetchHookDetailsData.enableInsightsWebhook,
        "enableFraudWebhook": hookSelector.fetchHookDetailsData.enableFraudWebhook
      }
    }

    if(item==='keyset'){
      hookObj = {
       "identifier": configuration.identifier,
       "publicKey": configuration.publicKey,
       "webhookUrl": hookSelector.fetchHookDetailsData.webhookUrl,
       "rotateEncryptionKey": true,
       "enableInsightsWebhook": hookSelector.fetchHookDetailsData.enableInsightsWebhook,
       "enableFraudWebhook": hookSelector.fetchHookDetailsData.enableFraudWebhook
     }
   }

   if(item==='insightset'){
    hookObj = {
     "identifier": configuration.identifier,
     "publicKey": configuration.publicKey,
     "webhookUrl": hookSelector.fetchHookDetailsData.webhookUrl,
     "rotateEncryptionKey": false,
     "enableInsightsWebhook": value,
     "enableFraudWebhook": hookSelector.fetchHookDetailsData.enableFraudWebhook
   }
 }

 if(item==='fraudset'){
  hookObj = {
   "identifier": configuration.identifier,
   "publicKey": configuration.publicKey,
   "webhookUrl": hookSelector.fetchHookDetailsData.webhookUrl,
   "rotateEncryptionKey": false,
   "enableInsightsWebhook": hookSelector.fetchHookDetailsData.enableInsightsWebhook,
   "enableFraudWebhook": value
 }
}
  
        dispatch(updateHookDetails({body:hookObj}))
  }


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);

    toast.success("Copied to Clipboard", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  useEffect(() => {
    if(application.applicationStatus === FetchDataStatus.INITIAL) {
      dispatch(getApplicationAsync({ accessToken: accessToken }))
    }

    if(application.applicationStatus === FetchDataStatus.FAILURE) {
      //navigate("/error");
      toast.error(application.errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [application.applicationStatus, dispatch, navigate])


  useEffect(()=>{

    if(configuration?.featureFlags?.isFraudMonitoringEnabled && 
      hookSelector.fetchHookDetailsStatus === PostDataStatus.INITIAL) {
      dispatch(getHookDetails({body:{
        "identifier": configuration.identifier,
        "publicKey": configuration.publicKey
      }}))
    }

    if(hookSelector.fetchHookDetailsStatus === PostDataStatus.FAILURE) {
      //navigate("/error");
    }

   

  
  },[hookSelector.fetchHookDetailsStatus,dispatch,navigate])

  useEffect(()=>{
    if(hookSelector.updateHookDetailsStatus===PostDataStatus.SUCCESS){
      dispatch(getHookDetails({body:{
        "identifier": configuration.identifier,
        "publicKey": configuration.publicKey
      }}))

      dispatch(resetUpdateHook())
    }
  },[hookSelector.updateHookDetailsStatus])


  useEffect(()=>{
      if(hookSelector.fetchHookDetailsStatus === FetchDataStatus.SUCCESS){
        setHookUrl(hookSelector.fetchHookDetailsData.webhookUrl)
      }
  },[hookSelector.fetchHookDetailsStatus])

  if(application.applicationStatus === FetchDataStatus.SUCCESS) {
    content = <div className="settings-section-container">
      <h2 className="detail-header settings-title">API Credentials</h2>
      <hr />
      <p className="text-light settings-subtitle" id="settings-api-setup-message">Set up your integrations with your ID and Secret Key</p>
      <div className="settings-call-to-action-container">
        <div className="settings-api-call-to-action-copy-fields">
          <label className="text-darker">API CLIENT ID</label>
          <div className="settings-input-field">
            <p>{Utils.checkNull(application.client.clientId)}</p>
            <button className="api-copy-button" onClick={() => copyToClipboard(application.client.clientId)} 
              disabled={Utils.isFieldEmpty(application.client.clientId)}>Copy</button>
          </div>
        </div>
        <div className="settings-api-call-to-action-copy-fields">
          <label className="text-darker">API SECRET KEY</label>
          <div className="settings-input-field">
            <p>{!Utils.isFieldEmpty(application.client.clientSecret) ? "************" : "N/A"}</p>
            <button className="api-copy-button" onClick={() => copyToClipboard(application.client.clientSecret)}
              disabled={Utils.isFieldEmpty(application.client.clientSecret)}>Copy</button>
          </div>
        </div>
      </div>
    </div>
  }


  if(application.applicationStatus === FetchDataStatus.FAILURE) {
    content = <div style={{display:"flex",
                           flexDirection:"column",
                           height:"200px",
                           justifyContent:"space-around",
                           alignItems:"center"}}>
                  <span style={{display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                padding:"5px"}}>
                      <img style={{width:"30px",height:"30px"}} src={errorImg}/></span>
                  <p style={{color:'#363C4F'}}>Something went wrong!</p>
                  <p style={{color:"red",fontWeight:"700",fontSize:"24px"}}>{application.errorMessage}</p>

    
              </div>
    }



    if(hookSelector.fetchHookDetailsStatus === PostDataStatus.SUCCESS) {
      contentThree = <div className="settings-section-container">
        <h2 className="detail-header settings-title">Webhook</h2>
        <hr />
        <p className="text-light settings-subtitle" id="settings-api-setup-message" style="color: #8d94a0;">Set your webhook url to allow you receive updates on requests made to the Insights API endpoints. See documentation <a href="https://insights-periculum.readme.io/reference/webhooks">here</a>.</p>
        <div className="settings-call-to-action-container-multiple">
  
          <div className="settings-api-call-to-action-copy-fields-multiple">
            <label className="text-darker">WEBHOOK URL</label>
            <div className="settings-input-field-hookurl">
              <input value={HookUrl} onChange={(e)=>setHookUrl(e.target.value)} placeholder="enter webhook url"/>
              <button className="api-copy-button" onClick={() => copyToClipboard(HookUrl)} 
                disabled={Utils.isFieldEmpty(HookUrl)}>
                Copy
              </button>

              <button className="api-copy-button" onClick={() => updateHook(HookUrl,'hookset')}>
                Save
              </button>
            </div>
          </div>

          <div className="settings-api-call-to-action-copy-fields-multiple">
            <label className="text-darker">WEBHOOK ENCRYPTION KEY</label>
            <div className="settings-input-field-hookurl">
              <section style={{width:'75%'}}>{!Utils.isFieldEmpty(hookSelector.fetchHookDetailsData.webhookEncrpytionKey) 
                  ? "************" : "N/A"
                  }
              </section>
              <button className="api-copy-button" 
                onClick={() => copyToClipboard(hookSelector.fetchHookDetailsData.webhookEncrpytionKey)}
                disabled={Utils.isFieldEmpty(hookSelector.fetchHookDetailsData.webhookEncrpytionKey)}>
                Copy
              </button>
  
              <button className="api-copy-button" 
                onClick={() => updateHook(true,'keyset')}>
                Reset
              </button>
            </div>
          </div>
  
          <div className="settings-api-call-to-action-copy-fields-multiple">
            <label className="text-darker">ENABLE INSIGHTS WEBHOOK</label>
            <div className="settings-input-field-hookurl">
              <section style={{width:'75%'}}>{hookSelector.fetchHookDetailsData.enableInsightsWebhook?'True':'False'}
              </section>
              <button className="api-copy-button" 
                onClick={() =>  updateHook(true,'insightset')}
                disabled={!hookSelector.fetchHookDetailsData.enableInsightsWebhook?false:true}>
                Enable
              </button>
  
              <button className="api-copy-button" 
                onClick={() => updateHook(false,'insightset')}
                disabled={hookSelector.fetchHookDetailsData.enableInsightsWebhook?false:true}>
                Disable
              </button>
            </div>
          </div>
  
  
         
        </div>
      </div>
    }
  
    if(hookSelector.updateHookDetailsStatus === PostDataStatus.FETCHING) {
      contentThree =<Spinner/>
    }
  
    if(hookSelector.fetchHookDetailsStatus === PostDataStatus.FAILURE) {
      contentThree = <div className="settings-section-container">
      <h2 className="detail-header settings-title">Webhook</h2>
      <hr />
      <p className="text-light settings-subtitle" id="settings-api-setup-message">Webhook Set-up not enabled</p>
      </div>
    
    }



  
  if(hookSelector.fetchHookDetailsStatus === PostDataStatus.SUCCESS) {
    contentTwo = <div className="settings-section-container">
      <h2 className="detail-header settings-title">Fraud Detect</h2>
      <hr />
      
      <div className="settings-call-to-action-container-multiple">
        <div className="settings-api-call-to-action-copy-fields-multiple">
          <label className="text-darker">FRAUD MONITORING URL</label>
          <div className="settings-input-field">
            <p>{monitoringBaseUrl}</p>
            <button className="api-copy-button" onClick={() => copyToClipboard(`${monitoringBaseUrl}`)} 
              disabled={Utils.isFieldEmpty(`${user.origin}`)}>Copy</button>
          </div>
        </div>


        <div className="settings-api-call-to-action-copy-fields-multiple">
          <label className="text-darker">ENABLE FRAUD WEBHOOK</label>
          <div className="settings-input-field-hookurl">
            <section style={{width:'75%'}}>{hookSelector.fetchHookDetailsData.enableFraudWebhook?'True':'False'}
            </section>
            <button className="api-copy-button" 
              onClick={() => updateHook(true,'fraudset')}
              disabled={!hookSelector.fetchHookDetailsData.enableFraudWebhook?false:true}>
              Enable
            </button>

            <button className="api-copy-button" 
              onClick={() => updateHook(false,'fraudset')}
              disabled={hookSelector.fetchHookDetailsData.enableFraudWebhook?false:true}>
              
              Disable
            </button>
          </div>
        </div>
      </div>
    </div>
  }

  if(hookSelector.updateHookDetailsStatus === PostDataStatus.FETCHING) {
    contentTwo =<Spinner/>
  }

  if(hookSelector.fetchHookDetailsStatus === PostDataStatus.FAILURE) {
    contentTwo = <div className="settings-section-container">
    <h2 className="detail-header settings-title">Fraud Detect</h2>
    <hr />
    <p className="text-light settings-subtitle" id="settings-api-setup-message">Fraud Set-up not enabled</p>
    </div>
  
  }


  return (
    <div className="settings-main-container">
      <div className="settings-section-container">
        <h2 className="detail-header settings-title">API Documentation</h2>
        <hr />
        <div className="settings-call-to-action-container">
          <div className="settings-call-to-action-header">
            <h3 className="text-darker">Explore</h3>
            <p className="text-light settings-subtitle">We have designed a simple and concise integration process guaranteed to deliver the best experience.</p>
          </div>
          <div className="settings-api-docs-call-to-action-button-container">
            <a href="https://insights-periculum.readme.io/reference/overview" target={"_blank"} rel="noreferrer">
              Explore our API Documentation
            </a>
          </div>
        </div>
      </div>

      {configuration.featureFlags.isScorecardSelfServiceEnabled && <div className="settings-section-container">
        <h2 className="detail-header settings-title">Manage Decision Engine</h2>
        <hr />
        <div className="settings-call-to-action-container">
          <div className="settings-call-to-action-header">
            <h3 className="text-darker">Explore</h3>
            <p className="text-light settings-subtitle">A concise and self-explanatory user-flow for customizing your decision engine models.</p>
          </div>
          <div className="settings-api-docs-call-to-action-button-container">
            <section onClick={()=>navigateToSelfService()} className='selfManage'>Manage Decision Engine</section>
          </div>
        </div>
      </div>}


      <section>
        <div>
          {content}
        </div>

        <div style={{marginTop:'30px'}}>
          { contentThree}
        </div>

        <div style={{marginTop:'30px'}}>
          {configuration?.featureFlags?.isFraudMonitoringEnabled && contentTwo}
        </div>
      </section>    
    </div>
  )
}

export default Api;